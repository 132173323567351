import { NormalizedHeaders, ParsedFile } from "@/models/wells";
import * as XLSX from "xlsx";

const MAX_PREVIEW_ROWS = 51; //50 rows + 1 header row
export const parseCsvText = (text: string, fileName: string): ParsedFile => {
  const lines = text.split(/\r\n|\n/).filter(Boolean);
  if (lines.length === 0) return { fileName, headers: [], rows: [] };

  const headerLine = lines[0];
  const rawHeaders = headerLine.split(",").map((h) => h.trim());

  const validColumnIndices = rawHeaders.reduce<number[]>((acc, header, idx) => {
    if (header !== "") acc.push(idx);
    return acc;
  }, []);
  const filteredHeaders = validColumnIndices.map((idx) => rawHeaders[idx]);

  const rows = lines.slice(1, MAX_PREVIEW_ROWS).map((line) => {
    const cells = line.split(",").map((cell) => cell.trim());
    return validColumnIndices.map((idx) => cells[idx] || "");
  });

  return {
    fileName,
    headers: filteredHeaders,
    rows,
  };
};

export const parseExcelFile = (data: ArrayBuffer, fileName: string): ParsedFile => {
  const workbook = XLSX.read(data, {
    type: "array",
    cellDates: false,
    cellText: true,
    raw: false,
  });

  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  if (!sheet || !sheet["!ref"]) {
    return { fileName, headers: [], rows: [] };
  }

  // Decode the entire range to read every cell manually
  const range = XLSX.utils.decode_range(sheet["!ref"]);
  const allRows: string[][] = [];

  // Collect each row
  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    const rowArr: string[] = [];

    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
      const cell = sheet[cellAddress];

      // cell?.w is the "formatted text" of the cell
      const displayedText = cell && cell.w ? cell.w.toString() : "";
      rowArr.push(displayedText.trim());
    }

    allRows.push(rowArr);
  }

  if (allRows.length === 0) {
    return { fileName, headers: [], rows: [] };
  }

  // first row as headers
  const rawHeaders = allRows[0];
  // Filter out empty headers
  const validColumnIndices = rawHeaders.reduce<number[]>((acc, header, idx) => {
    if (header !== "") acc.push(idx);
    return acc;
  }, []);

  const filteredHeaders = validColumnIndices.map((idx) => rawHeaders[idx]);

  // allRows.slice(1) => skip the header row
  const dataRows = allRows.slice(1, MAX_PREVIEW_ROWS).map((row) => {
    return validColumnIndices.map((idx) => {
      const cellValue = row[idx] || "";
      return cellValue.trim();
    });
  });

  return {
    fileName,
    headers: filteredHeaders,
    rows: dataRows,
  };
};

export const parseFile = (file: File): Promise<ParsedFile> => {
  return new Promise<ParsedFile>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const isCsv = file.name.toLowerCase().endsWith(".csv");
        if (!event.target?.result) return reject(new Error("No file content."));

        let parsed: ParsedFile;

        if (isCsv) {
          const text = event.target.result as string;
          parsed = parseCsvText(text, file.name);
        } else {
          const data = event.target.result as ArrayBuffer;
          parsed = parseExcelFile(data, file.name);
        }

        resolve(parsed);
      } catch (err) {
        console.error(`Error parsing file ${file.name}:`, err);
        reject(err);
      }
    };

    reader.onerror = (e) => {
      console.error(`Error reading file ${file.name}:`, e.target?.error);
      reject(e.target?.error);
    };

    if (file.name.toLowerCase().endsWith(".csv")) {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

// ------ HeaderMappingDialog Helper ------
export const MANUAL_WELL_TYPE_OPTIONS = ["Gas", "Water", "Oil"];
export const DIALOG_CONSTANTS = {
  NOT_USED: "< Not Used >",
  CHOOSE_WELL_TYPE: "< Choose Well Type >",
  CHOOSE_UNIT: "< Choose Unit >",
  UNRECOGNIZED_DATE: "< Unrecognized Date Format >",
  UNRECOGNIZED_DATE_MESSAGE: "Unrecognized date format. Please ensure your dates follow a supported pattern.",
  UNIT_NOT_SELECTED: "Please select a unit for this field.",
  DATE_FIELD: "Date",
  TAG_FIELD: "Tag",
};

// dateFormatter.ts
export const detectCustomDateFormat = (sample: string): string[] | null => {
  const re = /^(\d{1,4})(\D+)(\d{1,4})(\D+)(\d{1,4})$/;
  const match = sample.match(re);
  if (!match) return null;

  const [, g1, sep1, g2, sep2, g3] = match;
  const num1 = parseInt(g1, 10);
  const num2 = parseInt(g2, 10);
  const num3 = parseInt(g3, 10);

  const validDay = (d: number) => d >= 1 && d <= 31;
  const validMonth = (m: number) => m >= 1 && m <= 12;

  // if length=4 => '%Y', else if length=2 => '%y'
  const getYearToken = (yearStr: string) => {
    return yearStr.length === 4 ? "%Y" : "%y";
  };

  const results: string[] = [];

  // MDY => month=g1, day=g2, year=g3
  if (validMonth(num1) && validDay(num2)) {
    const yearToken = getYearToken(g3); // `%Y` or `%y`
    results.push(`%m${sep1}%d${sep2}${yearToken}`);
  }

  // YMD => year=g1, month=g2, day=g3
  // (We require g1.length === 4 or 2 to treat it as a year)
  if (g1.length === 4 && validMonth(num2) && validDay(num3)) {
    const yearToken = getYearToken(g1);
    results.push(`${yearToken}${sep1}%m${sep2}%d`);
  }

  // DMY => day=g1, month=g2, year=g3
  if (validDay(num1) && validMonth(num2)) {
    const yearToken = getYearToken(g3);
    results.push(`%d${sep1}%m${sep2}${yearToken}`);
  }

  const unique = Array.from(new Set(results));
  return unique.length ? unique : null;
};

const gatherAllSamples = (parsedFiles: ParsedFile[], header: string): string[] => {
  const samples: string[] = [];
  for (const file of parsedFiles) {
    const idx = file.headers.indexOf(header);
    if (idx < 0) continue;
    for (const row of file.rows) {
      const cellValue = (row[idx] || "").trim();
      if (cellValue) samples.push(cellValue);
    }
  }
  return samples;
};

// get the intersection of 2 array of date format
const intersectStringArrays = (arr1: string[], arr2: string[]) => {
  return arr1.filter((x) => arr2.includes(x));
};

export function getDateUnitFromFiles(parsedFiles: ParsedFile[], header: string): string[] | string {
  const allSamples = gatherAllSamples(parsedFiles, header);
  if (!allSamples.length) return DIALOG_CONSTANTS.UNRECOGNIZED_DATE;

  let finalFormats: string[] | null = null;

  for (const sample of allSamples) {
    const possibleForSample = detectCustomDateFormat(sample);
    if (!possibleForSample) return DIALOG_CONSTANTS.UNRECOGNIZED_DATE;

    if (finalFormats === null) {
      // First time => use the sample's possible formats as our baseline
      finalFormats = possibleForSample;
    } else {
      // Intersect with existing set
      finalFormats = intersectStringArrays(finalFormats, possibleForSample);
      if (!finalFormats.length) return DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
    }
  }

  return finalFormats && finalFormats.length ? finalFormats : DIALOG_CONSTANTS.UNRECOGNIZED_DATE;
}

export function pythonDateToDisplayFormat(pythonFormat: string): string {
  return pythonFormat.replace(/%Y/g, "YYYY").replace(/%y/g, "YY").replace(/%m/g, "M").replace(/%d/g, "D");
}

//----------------- Remove this after backend update gas,oil,water to rate -----------------
export const renameVolumeToRate = (obj: NormalizedHeaders): NormalizedHeaders => {
  const renameMap: Record<string, string> = {
    "Water Volume": "Water Rate",
    "Oil Volume": "Oil Rate",
    "Gas Volume": "Gas Rate",
  };

  const newObj: NormalizedHeaders = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = renameMap[key] || key; // if it's Water Volume, newKey=Water Rate, else key
    newObj[newKey] = value; // keep the same array-of-units or null
  }
  return newObj;
};

export const renameRateToVolume = (field: string | null): string | null => {
  if (!field) return field;
  if (field === "Water Rate") return "Water Volume";
  if (field === "Oil Rate") return "Oil Volume";
  if (field === "Gas Rate") return "Gas Volume";
  return field;
};
