import { useAppStore } from "@/features/app";
import ModulePage from "@/modules/ModulePage";

import { useState } from "react";
import { shallow } from "zustand/shallow";

import "./ModuleAutoRTA.css";

// dictionary
import dictionary from "@/constants/dictionary";

// container
import AutoRtaForecastContainer from "./components/AutoRtaForecast/AutoRtaForecastContainer";
import AutoRtaAnalysisContainer from "./components/Analysis/AutoRtaAnalysisContainer";

import AutoRtaProvider, { useAutoRtaState } from "./hooks/AutoRtaContext";
import AutoRtaInputGridContainer from "./components/inputGrid/AutoRtaInputGridContainer";
import DataviewPage from "@/features/modules/dataview";
import useExpandParameter from "@/utils/useExpandParameter";
import EconomicContainer from "@/features/modules/economics";

const ModuleAutoRta = ({ title }: { title: string }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const {
    dataSet,
    isLoading,
    autoRtaState,
    setApiError,
    apiError,
    setAutoRtaState,
    fluidType,
    project,
    setValidationError,
    validationError,
    setAnalysisCalculation,
    setAutoRtaForecastCalculation,
    setInputGridCalculate,
    flowingPressureOptions,
    inputGridCalculate,
    inputResultFromInitialize,
    haveChangeValue,
    setHaveChangeValue,
  } = useAutoRtaState();

  return (
    <ModulePage
      title={title}
      tabIndex={currentTab}
      onTabChange={(index) => {
        setCurrentTab(index);
        setApiError();
      }}
      tabs={
        dataSet && [
          {
            headerText: dictionary.dataView.title,
            itemIcon: "ScatterChart",
            disabled: isLoading,
            content: <DataviewPage />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.inputGrid,
            itemIcon: "InputField",
            disabled: isLoading,
            disableHideSidebar: true,
            content: (
              <AutoRtaInputGridContainer
                setHaveChangeValue={setHaveChangeValue}
                setValidationError={setValidationError}
                setApiError={setApiError}
                dataSet={dataSet}
                project={project}
                apiError={apiError}
                setAutoRtaState={setAutoRtaState}
                fluidType={fluidType}
                autoRtaState={autoRtaState}
                currentTab={currentTab}
                validationError={validationError}
                isLoading={isLoading}
                setAnalysisCalculation={setAnalysisCalculation}
                setAutoRtaForecastCalculation={setAutoRtaForecastCalculation}
                setInputGridCalculate={setInputGridCalculate}
                flowingPressureOptions={flowingPressureOptions}
                inputGridCalculate={inputGridCalculate}
                inputResultFromInitialize={inputResultFromInitialize}
              />
            ),
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.analysis,
            itemIcon: "LineChart",
            disabled: isLoading || validationError.length > 0,
            disableHideSidebar: true,
            content: <AutoRtaAnalysisContainer />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.forecast,
            itemIcon: "LineChart",
            disabled: isLoading || validationError.length > 0,
            disableHideSidebar: true,
            content: <AutoRtaForecastContainer currentTab={currentTab} isLoading={isLoading} />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.economics.title,
            itemIcon: "Money",
            disabled: isLoading || validationError.length > 0,
            disableHideSidebar: true,
            content: <EconomicContainer canFetch={currentTab === 4} haveChangeValue={haveChangeValue} setHaveChangeValue={setHaveChangeValue} />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

const AutoRtaWrapper = ({ title }: { title: string }) => {
  const { selectedDataSets, setApiError, project, isLoading, currentModule, apiError, setIsLoading, setProgress, setPollStatus } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      setApiError: state.setApiError,
      isLoading: state.isLoading,
      project: state.project,
      apiError: state.apiError,
      currentModule: state.currentModule,
      setIsLoading: state.setIsLoading,
      setProgress: state.setProgress,
      setPollStatus: state.setPollStatus,
    }),
    shallow
  );

  const { expandParameter } = useExpandParameter();

  return (
    <AutoRtaProvider
      expandParameter={expandParameter}
      setIsLoading={setIsLoading}
      setProgress={setProgress}
      setPollStatus={setPollStatus}
      currentModule={currentModule}
      apiError={apiError}
      project={project}
      isLoading={isLoading}
      setApiError={setApiError}
      selectedDataSets={selectedDataSets}
    >
      <ModuleAutoRta title={title} />
    </AutoRtaProvider>
  );
};
export default AutoRtaWrapper;
